<template>
    <div class="food zhong">
        <hd-com></hd-com>
        <div class="banner">
            <div class="banner-text">
                <h3>养生美容</h3>
                <p>YANG SHENG MEI RONG</p>
            </div>
        </div>
        <div class="content">
            <div class="content-box clearfix">
                <div class="content-left">
                    <div class="sidenav">
                        <h3 @click="searchBeauty(11)">全部</h3>
                        <el-menu class="el-menu-vertical-demo" unique-opened>
                            <template>
                                <el-submenu v-for="(sideNav, index) in beautyNavList" :key="index" :index="index + ''">
                                    <template slot="title">
                                        <span>{{ sideNav.categoryName }}</span>
                                    </template>
                                    <div class="subtitle">
                                        <el-menu-item @click="searchBeauty(sideNav.categoryId)" class="">
                                            <template slot="title">
                                                全部
                                            </template>
                                        </el-menu-item>
                                        <el-menu-item v-for="(subItem, i) in sideNav.children" :key="i"
                                            :index="subItem.categoryId + ''" @click="searchBeauty(subItem.categoryId)">
                                            <template slot="title">
                                                {{ subItem.categoryName }}
                                            </template>
                                        </el-menu-item>
                                    </div>
                                </el-submenu>
                            </template>
                        </el-menu>
                    </div>
                </div>
                <div class="content-right">
                    <div class="result-tit">
                        <p>为您找到 <span>{{ total }}</span><em>个结果</em></p>
                    </div>
                    <ul class="beauty-list">
                        <li class="beauty-item" v-for="(beautyDetailItem, i) in beautyDetailList">
                            <div @click="toBeautyDetail(beautyDetailItem.hashKey)">
                                <h3 class="beauty-tit">{{ beautyDetailItem.foodMaterialPrescriptionName }} <span
                                        v-if="category(beautyDetailItem)">{{ category(beautyDetailItem) }}</span></h3>
                                <p v-if="beautyDetailItem.source">出自： <em>{{ beautyDetailItem.source }}</em></p>
                            </div>
                        </li>
                    </ul>
                    <div id="page">
                        <el-pagination background layout="prev, pager, next,total" :page-size="15"
                            @current-change="handleCurrentChange" :current-page.sync="currentPage" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
import { Food } from '../../components/Drug/Food'
import hdCom from '../../components/header'
import ftCom from '../../components/footer'
import rtBar from "../../components/right";
var pageIndex = 1
var categoryId = 11
var keyword = ''
export default {
    name: "Beauty",
    components: {
        hdCom,
        ftCom,
        rtBar,
    },
    data() {
        var beauty = new Food(this.TokenClient, this.Services.Drug)
        return {
            beautyNavList: [],
            beautyDetailList: [],
            total: 0,
            currentPage: 1, //初始页
            rloading: [],//loading遮罩
            beautyDomain: beauty,
        }
    },
    mounted() {
        this.$bus.$emit('isShowChangeSize');
        this.$bus.$emit('select', 'food')
        this.getbeautyNavListData();
        keyword = this.$route.query.keyword
        this.searchBeauty(categoryId, pageIndex);
    },
    methods: {
        //左侧分类获取
        getbeautyNavListData: function () {
            var _this = this;
            _this.beautyDomain.Categorys(function (data) {
                // _this.beautyNavList = (data.data)
                for (let i = 0; i < data.data.length; i++) {
                    if (data.data[i].categoryId == 11) {
                        _this.beautyNavList = data.data[i].children
                    }
                }
            },
                function (error) {
                    console.log("养生美容分类请求失败:", error)
                })
        },
        //右侧养生美容列表获取
        searchBeauty: function (cateId, isPage = false) {
            categoryId = cateId;
            var _this = this;
            _this.rloading = _this.openLoading()
            if (!isPage) pageIndex = 1
            if (cateId == undefined) cateId = 11
            let params = `/${cateId}/${pageIndex}`
            if (keyword != '' && keyword != undefined) {
                params = params + `/${keyword}`
            } else {
                params += '/%20';
            }
            _this.beautyDomain.Foods(params, function (data) {
                _this.beautyDetailList = data.data.results
                _this.total = data.data.dataTotal;
                _this.rloading.close()
            },
                function (error) {
                    console.log("养生美容列表请求失败:", error)
                })
            if (!isPage) {
                this.currentPage = 1
            }
            // chrome
            document.body.scrollTop = 0
            // firefox
            document.documentElement.scrollTop = 0
            // safari
            window.pageYOffset = 0
        },
        //右侧养生美容属性获取
        category: function (data) {
            var categoryName = "";
            if (data.foodMaterialPrescriptionCategory != null) {
                if (data.foodMaterialPrescriptionCategory.Parent != null) {
                    categoryName = data.foodMaterialPrescriptionCategory.parent.categoryName + '/'
                }
                categoryName += data.foodMaterialPrescriptionCategory.categoryName;
            }
            return categoryName
        },
        //分页点击事件
        handleCurrentChange: function (pageNum) {
            this.currentPage = pageNum
            pageIndex = pageNum
            this.searchBeauty(categoryId, true);
            // chrome
            document.body.scrollTop = 0
            // firefox
            document.documentElement.scrollTop = 0
            // safari
            window.pageYOffset = 0
        },
        //跳转到详情
        toBeautyDetail(hashKey) {
            this.$router.push({
                path: '/Drug/FoodDetail',
                query: {
                    hashKey: hashKey,
                }
            })
        }
    },
    watch: {
        $route() {
            keyword = this.$route.query.keyword
            this.searchBeauty(categoryId, pageIndex);
        }
    }
}
</script>
<style scoped>
@import "../../assets/css/header.css";
@import "../../assets/css/zhongyaoItem.css";
@import "../../assets/css/footer.css";

.beauty-list .beauty-item {
    position: relative;
    padding: 15px 20px;
    margin-bottom: 10px;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.08);
    cursor: pointer;
}

.beauty-list .beauty-item:hover {
    box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.2);
}

.beauty-tit {
    font-size: 18px;
    font-weight: normal;
}

.beauty-tit span {
    font-size: 12px !important;
    color: #fff;
    background-color: #b76c12;
    border-radius: 10px;
    height: 18px;
    line-height: 18px;
    margin-left: 10px;
    padding: 0 4px;
    display: inline-block;
    position: relative;
    top: -2px;
}

.beauty-item p {
    margin-top: 10px;
    color: #666666;
}

.beauty-item p em {
    font-style: normal;
}

.sc-btn i {
    position: absolute;
    right: 43px;
    top: 15px;
    font-size: 26px;
    color: #b76c12;
    height: 36px;
    line-height: 36px;
    display: block;
    padding: 0 20px;
}
</style>
<style>
.beauty .bar-list .yysc {
    display: none !important;
}
</style>
